import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { localizedString } from '@languages';
import { getFields } from '@lib/utils/v1/getFields';
import classes from './VerifyDetails.style.module.scss';
import FormModel from './VerifyDetails.form';
import { Input, Checkbox, DatePicker, AddressFinder } from '../..';

function getResAddressValue(data, showDetailed) {
  let addr = '';
  const {
    homeAddress,
    addressLine1,
    street_number: streetNumber,
    street_name: streetName,
    suburb,
    postcode,
    state_territory: stateTerritory
  } = data;

  if (!showDetailed) {
    return homeAddress;
  }
  if (showDetailed) {
    if (addressLine1) {
      addr += `${addressLine1}, `;
    }

    if (streetNumber) {
      addr += `${streetNumber}, `;
    }

    if (streetName) {
      addr += `${streetName}, `;
    }

    if (suburb) {
      addr += `${suburb}, `;
    }

    if (stateTerritory) {
      addr += `${stateTerritory}, `;
    }

    if (postcode) {
      addr += postcode;
    }
    return addr;
  }

  return '';
}

const VerifyDetails = ({
  idType,
  country,
  countryOfIssue,
  onChange,
  onPencilClick,
  address,
  errors,
  checkConfirm,
  showDetailed,
  onShowDetailed,
  addressData,
  readOnly,
  useIdNumber,
  ...restProps
}) => {
  const fields = getFields({
    idType,
    countryOfIssue,
    address,
    addressData,
    useIdNumber,
    values: restProps
  });

  const isMatch = addressData && addressData.isMatch;

  const getValidationConfig = (fieldId) => {
    const isDriverLicense = idType.match(/LICENCE/i) || idType.match(/LICENSE/i);

    const validationconfig = { ...FormModel[fieldId] };
    if (isDriverLicense && fieldId === 'cardNumber') {
      validationconfig[fieldId] = { ...validationconfig[fieldId] };
    }

    return validationconfig;
  };

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field) => {
    const { id, label, value, date, addressFinder = false } = field;
    // if its date
    if (date) {
      const restPropsDate = {
        id,
        label,
        value
      };
      return (
        <Validation key={id} config={FormModel[id]} initialValues={{ [id]: value }}>
          {({ dirty, errors: formErrors, setField }) => {
            return (
              <DatePicker
                key={id}
                className={classNames(classes.input, classes['mb-20'])}
                hasError={dirty[id] ? formErrors[id] : null}
                onChange={(val) => {
                  onChange(id, val);
                  setField({ [id]: val });
                }}
                {...restPropsDate}
              />
            );
          }}
        </Validation>
      );
    }
    if (addressFinder) {
      return (
        <AddressFinder
          key={id}
          inputType="text"
          data={value}
          showDetailed={showDetailed}
          onShowDetailed={onShowDetailed}
          countryCode={country}
          onChange={(val) => onChange(id, val)}
        />
      );
    }
    return (
      <Validation key={id} config={getValidationConfig(id)} initialValues={{ [id]: value }}>
        {({ dirty, errors: formErrors, setField }) => {
          return (
            <Input
              key={id}
              className={classNames(classes.input, classes['mb-30'])}
              hasError={dirty[id] ? formErrors[id] : null}
              onChange={(val) => {
                onChange(id, val);
                setField({ [id]: val });
              }}
              {...field}
            />
          );
        }}
      </Validation>
    );
  });

  /**
   * Generate table.
   */
  const generateTableRows = fields.map(({ id, label, value }) => {
    return (
      <tr key={id}>
        <td>{label}</td>
        {id === 'addressData' && (
          <td>
            {getResAddressValue(value, showDetailed)}
            {!isMatch && !showDetailed && (
              <div className={classes.error}>{localizedString('enterValidAddress')}</div>
            )}
          </td>
        )}
        {id !== 'addressData' && (
          <Validation key={id} config={getValidationConfig(id)} initialValues={{ [id]: value }}>
            {({ errors: fieldErrors }) => {
              return (
                <td>
                  {value}
                  {fieldErrors[id] && <div className={classes.error}>{fieldErrors[id]}</div>}
                </td>
              );
            }}
          </Validation>
        )}
        <td>
          <svg
            onClick={onPencilClick}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.5396 10.8875L22.0976 8.1795L24.3536 5.3105L27.7966 8.0195L25.5396 10.8875ZM15.2096 24.0155L10.6106 26.3185L11.7676 21.3065L19.8406 11.0475L23.2816 13.7565L15.2096 24.0155ZM31.3086 6.1385L25.3556 1.4545C24.4656 0.753502 23.1746 0.907502 22.4736 1.7985L8.67858 19.3295C8.49058 19.5685 8.35858 19.8435 8.29058 20.1385L6.29058 28.8045C6.09558 29.6505 6.43658 30.4195 7.02158 30.8795C7.60558 31.3395 8.43458 31.4915 9.21058 31.1015L17.1626 27.1185C17.4336 26.9845 17.6706 26.7905 17.8566 26.5535L31.6516 9.0215C32.3536 8.1305 32.1996 6.8395 31.3086 6.1385Z"
            />
          </svg>
        </td>
      </tr>
    );
  });

  // const { ENABLE_CONSENT_CHECKBOX = true } = process.env
  const ENABLE_CONSENT_CHECKBOX = true;
  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <div className={classes.heading}>
        {readOnly ? localizedString('checkPhotoIDDetails') : localizedString('editDetails')}
      </div>
      {readOnly && (
        <div className={classes.title}>
          {idType === 'PASSPORT'
            ? localizedString('ensureDetailsBelowAllMatchOnYourPassport')
            : localizedString('ensureDetailsBelowAllMatchOnYourDriverLicence')}
        </div>
      )}
      <div className={classes.container}>
        {!readOnly && generateFields}
        {readOnly && (
          <table className={classes.table}>
            <tbody>{generateTableRows}</tbody>
          </table>
        )}
        {readOnly && ENABLE_CONSENT_CHECKBOX && (
          <Checkbox
            id="confirm"
            value={checkConfirm}
            onChange={(value) => onChange('checkConfirm', value)}
            label={localizedString('confirmAboveDetailsAreCorrectAndMatch')}
          />
        )}
      </div>
      {readOnly && (
        <div className={classes.disclaimer}>
          {localizedString('consentToMyInformationBeingCheckedWithDocumentIssuer')}
        </div>
      )}
    </div>
  );
};

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  address: PropTypes.string,
  addressData: PropTypes.object,
  readOnly: PropTypes.bool,
  useIdNumber: PropTypes.bool,
  country: PropTypes.string,
  countryOfIssue: PropTypes.string,
  onChange: PropTypes.func,
  onShowDetailed: PropTypes.func,
  onPencilClick: PropTypes.func,
  errors: PropTypes.object,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,
  showDetailed: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'AUS_AUTO_DRIVERLICENCE',
  errors: {},
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {},
  onShowDetailed: () => {},
  onPencilClick: () => {},
  showDetailed: false
};

export default VerifyDetails;
