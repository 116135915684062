import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import { FormValidation } from 'calidation';
import { LoadingSpinner, Page, ConfirmDetails } from '@components';
import { VerifyDetailsContent } from '@components/Contents';
import { localizedString } from '@languages';
import { isAgeEligible } from '@lib/utils/ageEligibility';
import { isCardNumberFieldVisible } from '@lib/utils/cardNumberField';
import {
  isPassportExpiredBy2Years,
  isEligibleForPassportExpiredLogic,
  isDocumentExpired
} from '@lib/utils/checkExpiredDocument';
import InfoAction from '../../store/actions/information';
import CaptureAction from '../../store/actions/capture';
import EditedAction from '../../store/actions/edited';
import APIs from '../../services/APIs';
import Address from '../../services/Address';
import { getAge, getCookie, setCookie, validateDate } from '../../lib/Utils';
import FormModel from './VerifyDetails.form';
import {
  CouldNotRead,
  CouldNotRecognise,
  ExpiredID,
  ExpiredIDBy2Years,
  ConfirmAge,
  Under18,
  ConfirmExtracted,
  ConfirmConsent,
  ConfirmGoBack,
  FaceNotDetectedInId,
  TooManyEdits,
  Recapture,
  IdNumberNotEditable,
  TooManyRetryAttempts,
  PoorQuality
} from './VerifyDetails.errors';
import { Error500 } from '../../errors';

class VerifyDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    retake: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    countryCode: PropTypes.string,
    location: PropTypes.string,
    verify: PropTypes.bool,
    idDetails: PropTypes.object,
    frontParams: PropTypes.object,
    setFrontIDParams: PropTypes.func,
    goToIdSelection: PropTypes.func,
    captureBack: PropTypes.func,
    setIdInfo: PropTypes.func,
    setEditedFields: PropTypes.func,
    appConfig: PropTypes.object
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    retake: () => null,
    idType: 'NZL_DRIVERLICENCE',
    countryCode: 'NZ',
    token: '',
    verify: false
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState(props) {
    const { editedFields } = props;
    return {
      data: {
        checkConfirm: false,
        checklicenceNumber: false,
        checklicenceVersion: false,
        checkpassportNumber: false,
        checkiDNumber: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      extracted: {},
      isLoading: true,
      isWaiting: false,
      /* disable primary button */
      disabled: true,
      confirmed: false,
      error: null,
      changes: editedFields,
      loading: false,
      countryOfIssue: '',
      useIdNumber: false
    };
  }

  /**
   * Call get card information API
   * @return {Void}
   */
  componentDidMount() {
    const {
      token,
      idDetails,
      idType,
      verify,
      frontParams,
      countryCode,
      setFrontIDParams,
      retake,
      goToIdSelection,
      captureBack,
      setIdInfo
    } = this.props;

    if (idDetails) {
      this.setState({
        countryOfIssue: idDetails.countryOfIssue,
        extracted: idDetails
      });
    }

    const { data } = this.state;

    const {
      ENABLE_CONSENT_CHECKBOX = true,
      ENABLE_VISA_AFTER_PASSPORT = false,
      APP_VERSION,
      ADDRESS_FINDER_KEY = null,
      ADDY_KEY = null,
      EXPERIAN_KEY = null,
      CAPTURE_BACK_ALL_AU_DL = null
    } = process.env;
    if (!ENABLE_CONSENT_CHECKBOX) {
      const newData = { ...data };
      data.checkConfirm = true;
      this.setState({
        data: newData
      });
    }

    if (!idDetails && token) {
      const noBack = idType === 'PASSPORT' || idType === 'ID_CARD';

      APIs.extractIdentifyInfo(token, noBack)
        .then(async ({ data: cardData, code, countryOfIssue, poorQuality = null, useIdNumber }) => {
          this.setState({
            countryOfIssue,
            extracted: cardData,
            useIdNumber
          });

          const buttons = [
            {
              large: true,
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                retake();
              }
            }
          ];

          // let impFields = ['firstName', 'middleName', 'lastName', 'dateOfBirth', 'expiryDate', 'licenceNumber', 'passportNumber']
          // let emptyFields = Object.keys(cardData).filter((key) => {
          //     return impFields.includes(key) && !cardData[key]
          // })

          if (code === '210') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '230') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '223') {
            const error = {
              component: FaceNotDetectedInId,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code !== '200') {
            const error = {
              component: CouldNotRead,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          /* if(emptyFields.length >= 3){
                        //throw error
                        const buttons = [
                            {
                                large: true,
                                label: 'Try again',
                                onClick: ()=> {
                                    this.props.setFrontIDParams({});
                                    this.props.retake()
                                }
                            }
                        ];
                        const error = {
                            component: CouldNotRead,
                            props: { buttons }
                        };
                        this.setState({
                            error,
                            isLoading: false
                        });
                        return;
                    } */

          if (poorQuality) {
            const buttons = [
              {
                label: localizedString('back'),
                variant: 'outline',
                onClick: goToIdSelection,
                dataTestId: 'capture-backBtn'
              },
              {
                label: localizedString('viewTips'),
                onClick: () => {
                  setFrontIDParams({});
                  retake();
                },
                dataTestId: 'betterImg-viewTipsBtn'
              }
            ];
            const error = {
              component: PoorQuality,
              props: { buttons, cropped: poorQuality }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          const dataCopy = { ...data, ...cardData };
          dataCopy.dateOfBirth = validateDate(dataCopy.dateOfBirth) ? dataCopy.dateOfBirth : '';
          dataCopy.expiryDate = validateDate(dataCopy.expiryDate) ? dataCopy.expiryDate : '';

          if (ADDRESS_FINDER_KEY || ADDY_KEY || EXPERIAN_KEY) {
            const addresses = await Address.find(dataCopy.address, countryCode, true);
            dataCopy.address = '';
            if (addresses && addresses.length > 0) {
              const adddr = addresses[0];
              dataCopy.address = adddr.full_address || adddr.a || adddr.text;
            }
          }

          if (frontParams.idType) {
            if (
              CAPTURE_BACK_ALL_AU_DL &&
              cardData.cardType.match(/AUS/i) &&
              cardData.cardType !== 'AUS_QLD_DRIVERLICENCE' &&
              cardData.cardType !== 'AUS_VIC_DRIVERLICENCE'
            ) {
              setIdInfo(dataCopy);
              captureBack('backOnlyCrop');
            } else if (
              APP_VERSION >= 3 &&
              (cardData.cardType === 'AUS_WA_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_SA_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_VIC_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_QLD_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_NT_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_TAS_DRIVERLICENCE')
            ) {
              captureBack('back');
              return;
            } else if (APP_VERSION < 3 && cardData.cardType === 'AUS_QLD_DRIVERLICENCE') {
              captureBack('back');
              return;
            } else if (
              (cardData.cardType === 'PASSPORT' || cardData.cardType === 'MRZ') &&
              ENABLE_VISA_AFTER_PASSPORT &&
              cardData.countryOfIssue &&
              cardData.countryOfIssue.toUpperCase() !== 'NEW ZEALAND' &&
              cardData.countryOfIssue.toUpperCase() !== 'AUSTRALIA'
            ) {
              captureBack('visa');
              return;
            }
          }

          if (!verify) {
            APIs.status('reviewInfo');
            this.setState({
              isLoading: false,
              data: dataCopy
            });
          } else {
            this.setState({
              isLoading: false,
              isWaiting: true,
              data: dataCopy
            });

            APIs.status('checkDocument').then(() => {
              APIs.checkApproval().then((status) => {
                this.setState({ isWaiting: false });
                if (status !== 'approved') {
                  const error = {
                    component: Recapture,
                    props: {
                      buttons: [
                        {
                          label: localizedString('recapture'),
                          onClick: () => {
                            setFrontIDParams({});
                            retake();
                          }
                        }
                      ]
                    }
                  };
                  this.setState({ error, isLoading: false });
                  return;
                }
                APIs.status('reviewInfo');
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                setFrontIDParams({});
                retake();
              }
            }
          };
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({ isLoading: false, data: idDetails });
    }
  }

  /**
   * Handle the continue button
   * @param {ClickEvent} e Event
   * @return {Void}
   */
  handleConfirm({ errors, isValid }) {
    const { data: newData, changes } = this.state;
    const data = { ...newData, edited: changes };
    const {
      idType,
      countryCode: COUNTRY,
      appConfig,
      setFrontIDParams,
      retake,
      setIdInfo,
      onNextStep
    } = this.props;
    const { LICENCEVERSION, REMOVE_ADDRESS_REVIEW = false } = process.env;
    const {
      checklicenceNumber,
      checklicenceVersion,
      checkpassportNumber,
      checkiDNumber,
      checkConfirm
    } = data;
    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    console.error(errors);
    const isPassport = idType === 'PASSPORT';
    const isPassportNoConfirm = isPassport && !checkpassportNumber;
    const isDLicenceNoConfirm =
      !checklicenceNumber ||
      (LICENCEVERSION && idType === 'NZL_DRIVERLICENCE' && !checklicenceVersion);

    const hasIdNumberNotConfirmed = COUNTRY === 'TR' && idType !== 'PASSPORT' && !checkiDNumber;

    if (
      ((!isPassport && isDLicenceNoConfirm) || isPassportNoConfirm) &&
      !idType.match(/IDCARD/i) &&
      !idType.match(/ID_CARD/i) &&
      !idType.match(/IDENTITYCARD/i) &&
      idType !== 'PROOF_OF_AGE'
    ) {
      const error = {
        component: ConfirmExtracted,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (!checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (hasIdNumberNotConfirmed) {
      const error = {
        component: ConfirmExtracted,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (!isValid) {
      return;
    }
    const { dateOfBirth, expiryDate, cardType, countryCode } = data;

    const { ENABLE_CONFIRM_AGE = true } = process.env;
    const age = getAge(dateOfBirth);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age)) {
      const error = {
        component: ConfirmAge,
        props: {
          buttons: [
            {
              label: localizedString('no'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('yes'),
              onClick: () => {
                const error = {
                  component: Under18,
                  props: {}
                };
                this.setState({ error });
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    const error = {
      component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
      props: {
        buttons: [
          {
            label: localizedString('tryAgain'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          },
          {
            label: localizedString('notExpired'),
            onClick: () => this.setState({ error: null })
          }
        ]
      }
    };

    if (isExpired) {
      this.setState({ error });
      return;
    }

    if (REMOVE_ADDRESS_REVIEW) {
      // Store details
      const { location, countryCode } = this.props;

      this.setState({ loading: true });
      APIs.store({
        ...data,
        location,
        countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
      })
        .then(({ status, type, msg }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => retake()
                    }
                  ]
                }
              });
            } else {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }
          setIdInfo(data);
          onNextStep();
        })
        .catch(({ message }) => {
          this.setState({ loading: false });
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                retake();
              }
            }
          };
          this.setState({ error, loading: false, isLoading: false });
        });
    } else {
      setIdInfo(data);
      onNextStep();
    }
  }

  /**
   * handle the checkbox button
   * @param {String} id
   * @param {Object} value
   * @return {Void}
   */
  async handleChange(id, value) {
    const { setEditedFields, setFrontIDParams, retake } = this.props;
    const { data, extracted = {}, changes } = this.state;
    const {
      FIELD_EDIT_RESTRICTION,
      FIELD_EDIT_LIMIT_COUNT = 3,
      ID_NUMBER_EDITABLE = true,
      ADDITIONAL_RECAPTURE_ALLOWED = null
    } = process.env;

    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    if (!id.match(/^check/)) {
      let filtered;
      if (value !== extracted[id]) {
        filtered = uniq([...changes, id]);
      } else {
        filtered = changes.filter((key) => {
          return key !== id;
        });
      }
      this.setState({ changes: filtered });
      setEditedFields(filtered);
    }

    const onRecaptureClick = () => {
      setEditedFields([]);
      if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
        const error = {
          component: TooManyRetryAttempts,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                large: true,
                shadow: true,
                onClick: () => {
                  setCookie('idCaptureAttempt', 0, -7);
                  /* let changes = [...this.state.changes]
                                    let dlIndex = changes.indexOf('licenceNumber');
                                    let passportIndex = changes.indexOf('passportNumber');
                                    if(dlIndex > 0){
                                        changes.splice(dlIndex, 1);
                                    }
                                    if(passportIndex > 0){
                                        changes.splice(passportIndex, 1);
                                    } */
                  this.setState({ error: null, changes });
                }
              }
            ]
          }
        };

        this.setState({ error });
      } else {
        setFrontIDParams({});
        retake();
      }
    };

    // Field Edit limit
    if (
      FIELD_EDIT_RESTRICTION &&
      changes &&
      changes.length > FIELD_EDIT_LIMIT_COUNT &&
      data.cardType !== 'PROOF_OF_AGE'
    ) {
      setFrontIDParams({});
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: TooManyEdits,
        props: {
          buttons: [
            {
              label: localizedString('tryAgain'),
              large: true,
              shadow: true,
              onClick: () => {
                onRecaptureClick();
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                onRecaptureClick();
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    if (['licenceNumber', 'passportNumber'].includes(id) && data.idNumber) {
      data.idNumber = value;
    }

    this.setState({
      data: {
        ...data,
        [id]: value
      }
    });
  }

  /**
   * Handle go back
   * @return {Void}
   */
  handleGoBack() {
    const { setFrontIDParams, retake } = this.props;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { isLoading, isWaiting, error, data, confirmed, loading, countryOfIssue, useIdNumber } =
      this.state;

    if (isWaiting) {
      return <LoadingSpinner title={localizedString('waitingForApproval')} />;
    }

    if (isLoading) {
      return <LoadingSpinner type="processingId" title={localizedString('processingYourID')} />;
    }

    const { component: Error, props: errorProps } = error || {};
    const { idType, onExit, countryCode: COUNTRY } = this.props;
    const { LICENCEVERSION, ENABLE_VISA_AFTER_PASSPORT } = process.env;
    const footerButtons = [
      {
        label: localizedString('exit'),
        variant: 'outline',
        onClick: onExit,
        dataTestId: 'details-exit'
      },
      {
        label: localizedString('confirm'),
        type: 'submit',
        loading,
        dataTestId: 'details-confirm'
      }
    ];

    delete FormModel.visaStartDate;
    delete FormModel.visaEndDate;

    if (idType.match(/DLCARD/i) || idType.match(/DRIVERLICENCE/i)) {
      if (!isCardNumberFieldVisible({ idType, countryOfIssue, ausState: data.state })) {
        delete FormModel.cardNumber.isRequired;
      }
      delete FormModel.passportNumber;
    } else if (idType.match(/IDCARD/i) || idType.match(/IDENTITYCARD/i)) {
      delete FormModel.passportNumber;
      delete FormModel.licenceNumber;
    } else if (idType === 'PROOF_OF_AGE') {
      delete FormModel.passportNumber;
      delete FormModel.licenceNumber;
      delete FormModel.expiryDate;
      delete FormModel.cardNumber.isRequired;
      delete FormModel.cardNumber.isRegexMatch;
    } else {
      delete FormModel.licenceNumber;
      delete FormModel.licenceVersion;
      delete FormModel.cardNumber;
    }

    if (idType !== 'PASSPORT' && !idType.match(/CAN/i) && COUNTRY !== 'NZ') {
      if (FormModel.licenceNumber) {
        delete FormModel.licenceNumber.isExactLength;
      }
    }

    if (!LICENCEVERSION || COUNTRY !== 'NZ') {
      if (FormModel.licenceVersion) {
        delete FormModel.licenceVersion;
      }
    }

    if (idType === 'CHN_IDCARD' && COUNTRY === 'CN') {
      if (FormModel.firstName) {
        delete FormModel.firstName.isRegexMatch;
      }
      if (FormModel.middleName) {
        delete FormModel.middleName.isRegexMatch;
      }
      if (FormModel.lastName) {
        delete FormModel.lastName.isRegexMatch;
        delete FormModel.lastName.isRequired;
      }
    }

    if (idType.match(/CAN/i) && idType.match(/DRIVERLICENCE/i) && COUNTRY === 'CA') {
      delete FormModel.lastName.isExactLength;
      delete FormModel.lastName.isRegexMatch;
      delete FormModel.passportNumber;
    }

    if (idType === 'PASSPORT') {
      delete FormModel.cardNumber;
      if (
        ENABLE_VISA_AFTER_PASSPORT &&
        countryOfIssue !== 'NEW ZEALAND' &&
        countryOfIssue !== 'AUSTRALIA'
      ) {
        FormModel.visaStartDate = {
          isRequired: localizedString('enterVisaStartDate'),
          isRegexMatch: {
            message: localizedString('invalidDate'),
            regex: /^([0-9-]+)$/
          }
        };
        FormModel.visaEndDate = {
          isRequired: localizedString('enterVisaEndDate'),
          isRegexMatch: {
            message: localizedString('invalidDate'),
            regex: /^([0-9-]+)$/
          }
        };
      } else {
        delete FormModel.visaStartDate;
        delete FormModel.visaEndDate;
      }
    }

    // Remove ID Number Field
    if (COUNTRY !== 'TR' || (COUNTRY === 'TR' && idType === 'PASSPORT')) {
      delete FormModel.iDNumber;
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}

        {!confirmed && !isLoading && !isWaiting && !Error && (
          <ConfirmDetails onClick={() => this.setState({ confirmed: true })} />
        )}
        {!isLoading && (
          <FormValidation onSubmit={this.handleConfirm} config={FormModel} initialValues={data}>
            {({ errors, setField }) => {
              return (
                <Page
                  title={localizedString('confirmYourDetails')}
                  onGoBack={this.handleGoBack}
                  buttons={footerButtons}
                >
                  <VerifyDetailsContent
                    {...data}
                    useIdNumber={useIdNumber}
                    idType={idType}
                    errors={errors}
                    country={COUNTRY}
                    countryOfIssue={countryOfIssue}
                    onChange={(id, value) => {
                      this.handleChange(id, value);
                      setField({ [id]: value });
                    }}
                  />
                </Page>
              );
            }}
          </FormValidation>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, capture, appConfig, edited }) {
  return {
    idDetails: information.idDetails,
    frontParams: capture.frontParams,
    editedFields: edited.fields,
    appConfig
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setIdInfo: (data) => dispatch(InfoAction.setIdInfo(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
