import { DOCUMENTS } from '@lib/constants/documents';

export const isCardNumberFieldVisible = ({
  idType = '',
  countryOfIssue = '',
  ausState = '',
  skipDigitalDriverLicence = false
}) => {
  if (!idType.match(/LICENCE/i) && !idType.match(/LICENSE/i)) {
    return false;
  }

  if (DOCUMENTS.DIGITAL_DRIVER_LICENCE.cardType === idType && skipDigitalDriverLicence) {
    return false;
  }

  const cardNumberAusStates = ['NSW', 'ACT', 'SA', 'TAS', 'WA', 'NT', 'QLD'];
  return countryOfIssue.toUpperCase() === 'AUSTRALIA' && cardNumberAusStates.includes(ausState);
};
