import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import Animation from '@components/Animation';
import parse from 'html-react-parser';
import classes from './BackOfCard.style.module.scss';

let animationBackCard;
import('@FLOW_V2_FLOW/animations/anim_back_card.json').then((value) => {
  animationBackCard = value;
});

/**
 * Back of card animation.
 */
export default function BackOfCard({ idType }) {
  const { FLOW_V2_BACKCARD_DESCRIPTION = '' } = process.env;

  const backDesc = idType.match(/DRIVERLICENCE/i)
    ? localizedString('backOfDriverLicence')
    : localizedString('backOfCard');

  const continueDesc1 = idType.match(/DRIVERLICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence1')
    : localizedString('toContinueIDVerificationCard1');
  const continueDesc2 = idType.match(/DRIVERLICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence2')
    : localizedString('toContinueIDVerificationCard2');
  let continueDesc = `${continueDesc1}<br/>${continueDesc2}`;
  if (FLOW_V2_BACKCARD_DESCRIPTION !== '') {
    continueDesc = FLOW_V2_BACKCARD_DESCRIPTION;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>{backDesc}</div>
      <div className={classes.description}>{parse(continueDesc)}</div>
      <div className={classes['animation-container']}>
        <Animation scale={1.2} animationData={animationBackCard} />
      </div>
    </div>
  );
}

BackOfCard.propTypes = {
  idType: PropTypes.string
};

BackOfCard.defaultProps = {
  idType: 'PASSPORT'
};
