import React from 'react';
import Props from 'prop-types';
import Modal from '@lib/components/v2/Modal';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import { Message } from '../../components';

/**
 * If card blurry or crop etc.
 */
export const CouldNotRead = (props) => {
  return (
    <Message {...props} title={localizedString('weCouldNotReadYourIDInPhoto')} issue>
      <ul>
        <li>{localizedString('makeSureNoPartsOfCardAreCovered')}</li>
        <li>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
      </ul>
    </Message>
  );
};

/**
 * Wrong document.
 */
export const CouldNotRecognise = (props) => {
  const {
    FLOW_V2_COULD_NOT_RECOGNISE_ALERT_TITLE = localizedString('couldNotRecogniseYourID'),
    FLOW_V2_COULD_NOT_RECOGNISE_ALERT_DESCRIPTION = localizedString('pleaseViewTipsRecapture')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_COULD_NOT_RECOGNISE_ALERT_TITLE} issue>
      {parse(FLOW_V2_COULD_NOT_RECOGNISE_ALERT_DESCRIPTION)}
    </Message>
  );
};

/**
 * Document not accepted.
 */
export const DocNotAccepted = (props) => {
  const {
    FLOW_V2_DOC_NOT_ACCEPTED_ALERT_TITLE = localizedString('docNotAccepted'),
    FLOW_V2_DOC_NOT_ACCEPTED_ALERT_DESCRIPTION = localizedString('viewAndSelectAcceptedIdDocs')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_DOC_NOT_ACCEPTED_ALERT_TITLE} issue>
      {parse(FLOW_V2_DOC_NOT_ACCEPTED_ALERT_DESCRIPTION)}
    </Message>
  );
};

/**
 * Poor Quality Image.
 */
export const PoorQuality = (props) => {
  const {
    FLOW_V2_POOR_QUALITY_TITLE = localizedString('needBetterImage'),
    FLOW_V2_POOR_QUALITY_DESCRIPTION1 = localizedString('insufficientQualityImage'),
    FLOW_V2_POOR_QUALITY_DESCRIPTION2 = localizedString('viewTipsToRecapture')
  } = process.env;

  const { cropped, ...restProps } = props;
  const img = <img data-testid="betterImg-img" alt="" src={cropped} style={{ width: '100%' }} />;
  return (
    <Message {...restProps} title={FLOW_V2_POOR_QUALITY_TITLE} alignCenterVertical={false}>
      <p data-testid="betterImg-txt1">{parse(FLOW_V2_POOR_QUALITY_DESCRIPTION1)}</p>
      {img}
      <p data-testid="betterImg-txt2">{parse(FLOW_V2_POOR_QUALITY_DESCRIPTION2)}</p>
    </Message>
  );
};

PoorQuality.propTypes = {
  cropped: Props.string
};

/**
 * If image has poor quality.
 */
export const PoorImage = (props) => {
  const style = { color: '#fff' };
  return (
    <Message {...props} title={localizedString('poorImageQuality')} issue>
      <ul>
        <li style={style}>{localizedString('makeSureThereIsEnoughLight')}</li>

        <li style={style}>{localizedString('makeSureYourPhotoIsInFocusAndNotBlurry')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
      </ul>
    </Message>
  );
};

/**
 * If ID has been expired.
 */
export const ExpiredID = (props) => {
  const {
    FLOW_V2_EXPIRED_ID_HEADING = localizedString('yourIDExpired_FlowV2'),
    FLOW_V2_EXPIRED_ID_DESCRIPTION = localizedString(
      'weCanNotContinueWithYourApplicationWithExpiredID_FlowV2'
    )
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_EXPIRED_ID_HEADING} issue>
      {parse(FLOW_V2_EXPIRED_ID_DESCRIPTION)}
    </Message>
  );
};

/**
 * If ID has been expired by more than 2 years.
 */
export const ExpiredIDBy2Years = (props) => {
  const {
    FLOW_V2_EXPIRED_ID_BY_2_YEARS_HEADING = localizedString('passportExpiredOverTowYearsAgo'),
    FLOW_V2_EXPIRED_ID_BY_2_YEARS_DESCRIPTION = localizedString('cannotAcceptExpiredId')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_EXPIRED_ID_BY_2_YEARS_HEADING} issue>
      {FLOW_V2_EXPIRED_ID_BY_2_YEARS_DESCRIPTION}
    </Message>
  );
};

/**
 * Confirm user age.
 */
export const ConfirmAge = (props) => {
  const { ELIGIBLE_AGE_CONFIRMATION = localizedString('areYouUnderEligibleAge') } = process.env;

  return <Modal isOpen {...props} heading={ELIGIBLE_AGE_CONFIRMATION} />;
};

/**
 * Confirm user age.
 */
export const UnderEligibleAge = (props) => {
  const {
    UNDER_ELIGIBLE_AGE_TITLE = localizedString('weCannotContinueBecauseYouAreUnderEligibleAge')
  } = process.env;

  return (
    <Message {...props} issue title={UNDER_ELIGIBLE_AGE_TITLE}>
      {localizedString('sessionCancelledDesc1')}
      <br />
      <br />
      {localizedString('sessionCancelledDesc2')}
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const FaceNotDetectedInId = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} title={localizedString('weCouldNotRecogniseYourID')}>
      <ul>
        <li style={style}>{localizedString('facePictureOnCardShouldBeClear')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li style={style}>{localizedString('makeSureIDCleanFromAnyGrime')}</li>
        <li style={style}>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li style={style}>{localizedString('makeSureAllEdgesAreShown')}</li>
      </ul>
    </Message>
  );
};

/**
 * Confirm extracted details
 */
export const ConfirmExtracted = (props) => {
  return <Modal isOpen {...props} heading={localizedString('pleaseConfirmExtractedAreCorrect')} />;
};

/**
 * Confirm consent
 */
export const ConfirmConsent = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('pleaesConfirmConsentToCheckDetails')} />
  );
};

/**
 * Confirm going back
 */
export const ConfirmGoBack = (props) => {
  return (
    <Modal isOpen heading={localizedString('leaveConfirmation')} {...props}>
      {localizedString('scanIDAgainInformationOnScreen')}
    </Modal>
  );
};

/**
 *  Recapture
 */
export const Recapture = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('attendantRequestedYouToRecaptureID')} />
  );
};
/**
 * ID number not editable.
 */
export const IdNumberNotEditable = (props) => {
  return (
    <Message {...props} title={localizedString('idNumberNotEditable')} issue>
      {localizedString('ifIDNumberNotCorrectPleaseTakeAnotherPhoto')}
    </Message>
  );
};

/**
 * ID number not editable.
 */
export const TooManyRetryAttempts = (props) => {
  return (
    <Message {...props} title={localizedString('tooManyAttempts')} issue>
      {localizedString('exceedNumberOfAttemptsToCaptureID')}
      <br />
      <br />
      {localizedString('ifStillIncorrectCanContinueAndWeWillContactYou')}
    </Message>
  );
};

/**
 * Edit too many fields.
 */
export const EditTooManyFields = (props) => {
  const {
    FLOW_V2_EDIT_TOO_MANY_FIELDS_TITLE = localizedString('editTooManyFields'),
    FLOW_V2_EDIT_TOO_MANY_FIELDS_DESCRIPTION = localizedString(
      'ifYourDataHasBeenExtractedIncorrectlyOnMultipleFields'
    )
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_EDIT_TOO_MANY_FIELDS_TITLE} issue>
      {FLOW_V2_EDIT_TOO_MANY_FIELDS_DESCRIPTION}
    </Message>
  );
};

/**
 * Select Another ID
 */
export const SelectAnotherIdException = (props) => {
  return (
    <Message {...props} title={localizedString('captureSecondDoc')} issue>
      {localizedString('captureSecondDocDesc')}
    </Message>
  );
};
