/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Address from '@services/Address';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { Input, Select, CountrySelect } from '@lib/components/v2/Form';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import { short, detailed } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';

export default class AddressFinder extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onShowDetailed: PropTypes.func,
    countryCode: PropTypes.string,
    dataTestId: PropTypes.string,
    countries: PropTypes.array
  };

  static defaultProps = {
    inputType: 'text',
    data: {},
    onChange: () => null,
    onShowDetailed: () => null
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleChange = debounce(
      this.handleChange.bind(this),
      ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS
    );
    this.handleSelect = this.handleSelect.bind(this);
    this.addressListPanelBottomRef = null;
  }

  /**
   * Return the component's initial state
   *
   */
  getInitialState(props) {
    const { DEFAULT_COUNTRY_CODE = 'AU', DEFAULT_COUNTRY_LABEL = 'Australia' } = process.env;

    const {
      homeAddress,
      addressLine1,
      street_number,
      street_name,
      suburb,
      postcode,
      state_territory,
      isMatch
    } = props.data || {};

    const country = {
      value: DEFAULT_COUNTRY_CODE,
      label: DEFAULT_COUNTRY_LABEL
    };

    return {
      homeAddress,
      address: homeAddress,
      addresses: {
        address: []
      },
      isMatch: props.countryCode === 'OTHER' ? false : isMatch,
      country,
      detailedAddress: {
        addressLine1,
        addressLine2: '',
        street_number,
        street_name,
        suburb,
        postcode,
        state_territory,
        country: country.label
      },
      filteredCountries: [],
      showDetailed: props.showDetailed,
      addressApiCalls: 0
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryCode } = this.props;
    const isMatch = countryCode === 'OTHER' ? false : nextProps.data.isMatch;
    this.setState({
      isMatch
    });
  }

  /**
   * Handle the change of detailed address in case match failed
   */
  handleChangeAddress = (detailedAddress) => {
    const { addressApiCalls } = this.state;
    const data = {
      ...detailedAddress,
      homeAddress: '',
      isMatch: false,
      addressApiCalls
    };

    this.props.onChange(data);
    this.setState({
      detailedAddress
    });
  };

  /**
   * Handle the change of the address
   * @param id
   * @param  {String} value
   * @return {Void}
   */
  handleChange(id, value) {
    const { countryCode, onChange } = this.props;
    const { addressApiCalls } = this.state;
    let addressApiCallsN = addressApiCalls;

    const increaseApiCalls =
      (Address.ALLOWED_COUNTRIES.includes(countryCode) &&
        (Address.API_KEY || Address.ADDY_API_KEY)) ||
      (countryCode && Address.EXPERIAN_API_KEY);
    if (increaseApiCalls) {
      ++addressApiCallsN;
      this.setState({
        addressApiCalls: addressApiCallsN
      });
    }

    Address.find(value, countryCode).then(async (addresses) => {
      const { detailedAddress } = this.state;
      const data = {
        ...detailedAddress,
        homeAddress: value,
        isMatch: false,
        addressApiCalls: addressApiCallsN,
        apiDisabled: Address.isAddressAPIDisabled(countryCode)
      };
      const detailedAddresss = { ...detailedAddress };

      await this.setState({
        addresses: {
          [id]: addresses,
          detailedAddress: detailedAddresss,
          apiDisabled: data.apiDisabled
        }
      });

      if (this.addressListPanelBottomRef) {
        this.addressListPanelBottomRef.scrollIntoView({ behavior: 'smooth' });
      }
      onChange(data);
    });
  }

  /**
   * Handle select the address
   * @param id
   * @param  {String} address
   * @param format
   * @return {Void}
   */
  handleSelect(id, address, format) {
    const { onChange } = this.props;
    const { addressApiCalls } = this.state;
    const data = {
      homeAddress: address,
      isMatch: true,
      addressApiCalls
    };

    if (id === 'address') {
      if (format) {
        Address.verifyExperian(format).then(({ fullAddress, ...detailedAddress }) => {
          this.setState(({ addresses }) => ({
            homeAddress: address,
            addresses: { ...addresses, address: [] },
            detailedAddress
          }));
          data.homeAddress = address;
          data.addressApiCalls += 1;

          Object.keys(detailedAddress).forEach((key) => {
            data[key] = detailedAddress[key];
          });
        });
      } else {
        this.setState(({ addresses }) => ({
          homeAddress: address,
          addresses: { ...addresses, address: [] }
        }));
      }
    }

    onChange(data);
  }

  showManualFields = (e) => {
    e.preventDefault();
    this.setState({
      showDetailed: true
    });

    this.props.onShowDetailed(true);
  };

  /**
   * Generate additional fields for detailed address
   * @return
   */
  generateAddressFields() {
    const { countries } = this.props;
    const { detailedAddress, country } = this.state;

    let states = {
      AU: [
        {
          label: 'ACT',
          value: 'ACT'
        },
        {
          label: 'NSW',
          value: 'NSW'
        },
        {
          label: 'NT',
          value: 'NT'
        },
        {
          label: 'QLD',
          value: 'QLD'
        },
        {
          label: 'SA',
          value: 'SA'
        },
        {
          label: 'TAS',
          value: 'TAS'
        },
        {
          label: 'VIC',
          value: 'VIC'
        },
        {
          label: 'WA',
          value: 'WA'
        }
      ]
    };
    states = states[country.value];

    const {
      FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 = '' // ex; AU
    } = process.env;

    const filteredCountries =
      FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 !== ''
        ? [FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2]
        : countries;

    return (
      <Validation initialValues={detailedAddress} config={detailed}>
        {({ errors, setField }) => (
          <div className={classes.detailedWrapper}>
            <div className={classNames(classes.countries)}>
              <CountrySelect
                filter={filteredCountries}
                value={country}
                onChange={(value) => {
                  this.setState({ country: value });
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.country = value.label;
                  detailedAddr.selectedManualCountryCode = value.value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ country: value.label });
                }}
              />
            </div>
            <Input
              type="text"
              id="addressLine1"
              placeholder={localizedString('addressLine1')}
              className={classNames(classes.input)}
              hasError={errors.addressLine1}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.addressLine1 = value;
                this.handleChangeAddress(detailedAddr);
                setField({ addressLine1: value });
              }}
              value={detailedAddress.addressLine1 || ''}
            />
            <Input
              type="text"
              id="addressLine2"
              placeholder={localizedString('addressLine2')}
              className={classNames(classes.input)}
              hasError={errors.addressLine2}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.addressLine2 = value;
                this.handleChangeAddress(detailedAddr);
                setField({ addressLine2: value });
              }}
              value={detailedAddress.addressLine2 || ''}
            />
            <Input
              type="text"
              id="suburb"
              placeholder={localizedString('suburbOrCity')}
              className={classNames(classes.input)}
              hasError={errors.suburb}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.suburb = value;
                this.handleChangeAddress(detailedAddr);
                setField({ suburb: value });
              }}
              value={detailedAddress.suburb || ''}
            />
            <div className={classes.inputGroup}>
              <Input
                type="text"
                id="postcode"
                placeholder={localizedString('postcode')}
                autocomplete="none"
                hasError={errors.postcode}
                onChange={(value) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.postcode = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ postcode: value });
                }}
                value={detailedAddress.postcode || ''}
              />
              {country.label === 'Australia' && (
                <div style={{ position: 'relative' }}>
                  <Select
                    id="state_territory"
                    borderBottomOnly
                    placeholder={localizedString('state')}
                    hasError={errors.state_territory}
                    options={states}
                    onChange={(state) => {
                      const detailedAddr = { ...detailedAddress };
                      detailedAddr.state_territory = state.value;
                      this.handleChangeAddress(detailedAddr);
                      setField({ state_territory: state.value });
                    }}
                    value={
                      detailedAddress.state_territory
                        ? {
                            label: detailedAddress.state_territory,
                            value: detailedAddress.state_territory
                          }
                        : null
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Validation>
    );
  }

  render() {
    const { inputType, countryCode, dataTestId } = this.props;
    const { homeAddress, addresses, showDetailed, isMatch } = this.state;
    const apiDisabled = Address.isAddressAPIDisabled(countryCode);

    const {
      ENABLE_ENTER_ADDRESS_MANUALLY = true,
      FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER = localizedString('residentalAddressPlaceholder')
    } = process.env;

    const addressesList = (id, data) => {
      if (data && data.length) {
        return (
          <ul className={classes.addresses}>
            {data.map(({ a, full_address, text, format = null }, index) => (
              <li
                onClick={() => this.handleSelect(id, a || full_address || text, format)}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <span>
                  <img width="18" alt="" src="images/icons/png/map-pointer-videoid.png" />
                </span>{' '}
                {a || full_address || text}
              </li>
            ))}
            {ENABLE_ENTER_ADDRESS_MANUALLY && (
              <li className="text-right">
                <span onClick={this.showManualFields} className="font-weight-bold pt-2">
                  {localizedString('enterManually')}{' '}
                </span>{' '}
                <span>
                  <img width="18" alt="" src="images/icons/png/forward-videoid.png" />
                </span>
              </li>
            )}
          </ul>
        );
      }
      return null;
    };

    return (
      <div className={classNames(classes.wrapper)}>
        {!showDetailed && (
          <div className={classes.inputWrapper}>
            <Validation config={short} initialValues={{ homeAddress }}>
              {({ dirty, errors, setField, submitted }) => {
                let addressError = dirty.homeAddress || submitted ? errors.homeAddress : null;
                if (
                  !showDetailed &&
                  !apiDisabled &&
                  !isMatch &&
                  dirty.homeAddress &&
                  addresses.address.length < 1
                ) {
                  addressError = localizedString('enterValidAddress');
                }
                return (
                  <Input
                    type={inputType}
                    id="homeAddress"
                    required
                    label={localizedString('residentalAddress')}
                    placeholder={FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER}
                    hasError={addressError}
                    onChange={(value) => {
                      this.setState({ homeAddress: value });
                      setField({ homeAddress: value });

                      if (value && value.trim() !== (homeAddress || '').trim()) {
                        this.handleChange('address', value);
                      }
                    }}
                    value={homeAddress}
                    className={classes.inputElement}
                    dataTestId={dataTestId}
                  />
                );
              }}
            </Validation>
            <span className={classes.icon}>
              <img alt="" src="images/icons/png/search.png" />
            </span>
            {addressesList('address', addresses.address)}
            <div
              ref={(ref) => {
                this.addressListPanelBottomRef = ref;
              }}
            />
          </div>
        )}
        {showDetailed && this.generateAddressFields()}
      </div>
    );
  }
}
