import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import { Checkbox } from '@lib/components/v2/Form';
import { localizedString } from '@languages';

import classes from './Privacy.style.module.scss';

const Privacy = ({ onAccept, onSelectLanguage }) => {
  const [state, setState] = useState({ confirmed: false });
  const { backurl } = document.body.dataset;
  let buttons = [
    {
      label: localizedString('continue'),
      disabled: !state.confirmed,
      onClick: () => {
        if (state.confirmed) {
          onAccept();
        }
      }
    }
  ];

  if (backurl) {
    buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          window.location.href = backurl;
        }
      },
      {
        label: localizedString('continue'),
        disabled: !state.confirmed,
        onClick: () => {
          if (state.confirmed) {
            onAccept();
          }
        }
      }
    ];
  }

  return (
    <Page
      title={localizedString('privacyTitle')}
      isPrivacy
      buttons={buttons}
      className={classes.wrapper}
      onSelectLanguage={onSelectLanguage}
    >
      <div className={classes.heading}>{localizedString('privacyConsent')}</div>
      <div className={classes.content}>
        To verify your identity without the requirement for you to go to a physical location, we
        will use the camera on your phone to capture images of your face and your ID documents. As
        such we will be needing your consent to access your camera to receive the images and to
        receive your location data for security purposes.
        <br />
        <br />
        This data will be collected by Service Victoria and may be used to match against the records
        of a 3rd party ID documents service provider.
        <br />
        <br />
        Without your acceptance of this declaration, you will need to verify your identity through
        another way as accepted by Service Victoria.
        <br />
        <br />
        Please press “Accept” and continue if you consent for Service Victoria to perform this
        identity verification check.
        <br />
        <br />
        <Checkbox
          id="confirm"
          value={state.confirmed}
          onChange={(value) => setState({ confirmed: value })}
          label={localizedString('confirmThatIHaveReadAndAcceptThePrivacyConsent')}
        />
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  onSelectLanguage: PropTypes.func
};

export default Privacy;
