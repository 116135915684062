const japaneseUnicodeRanges = {
  hiragana: '\u3041-\u3096',
  katakana: '\u30A0-\u30FF',
  kanji: '\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A',
  kanjiRadicals: '\u2E80-\u2FD5',
  katakanaAndPunctuation: '\uFF5F-\uFF9F',
  japaneseSymbolsAndPunctuation: '\u3000-\u303F'
};

const {
  hiragana,
  katakana,
  kanji,
  kanjiRadicals,
  katakanaAndPunctuation,
  japaneseSymbolsAndPunctuation
} = japaneseUnicodeRanges;

export const noEmojies = new RegExp(
  `^([\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u2019${hiragana}${katakana}${kanji}${kanjiRadicals}${katakanaAndPunctuation}${japaneseSymbolsAndPunctuation}]+)$`
);
