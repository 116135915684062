import { localizedString } from '@languages';

import {
  fieldsValidation,
  defaultLettersAndSymbolsRegexMatch
} from '@lib/validations/vefifyDetailsFormValidations';

const {
  FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME = false,
  FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME = false,
  VERIFY_DETAILS_MESSAGE_LAST_NAME = localizedString('pleaseEnterLastName')
} = process.env;

const validations = { ...fieldsValidation };

if (FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
  validations.firstName = {
    firstName: {
      isRequired: {
        message: localizedString('pleaseEnterFirstName'),
        validateIf: ({ fields }) =>
          fields.confirmFirstNameIsEmpty === '' || fields.confirmFirstNameIsEmpty === false
      },
      isRegexMatch: {
        ...defaultLettersAndSymbolsRegexMatch,
        validateIf: ({ fields }) =>
          fields.confirmFirstNameIsEmpty === '' || fields.confirmFirstNameIsEmpty === false
      }
    },
    confirmFirstNameIsEmpty: {
      isRequired: {
        message: '',
        validateIf: ({ fields }) => fields.firstName === ''
      }
    }
  };
}

if (FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME) {
  validations.middleName = {
    middleName: {
      ...validations.middleName.middleName,
      isMinLength: {
        message: localizedString('pleaseEnterFullMiddleName'),
        length: 2
      }
    }
  };
}

if (FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
  const validateIf = ({ fields }) =>
    fields.confirmMiddleNameIsEmpty === '' || fields.confirmMiddleNameIsEmpty === false;

  validations.middleName = {
    middleName: {
      ...validations.middleName.middleName,
      isMinLength: {
        message: localizedString('pleaseEnterFullMiddleName'),
        length: 2,
        validateIf
      },
      isRequired: {
        message: localizedString('pleaseEnterMiddleName'),
        validateIf
      },
      isRegexMatch: {
        ...defaultLettersAndSymbolsRegexMatch,
        validateIf
      }
    },
    confirmMiddleNameIsEmpty: {
      isRequired: {
        message: '',
        validateIf: ({ fields }) => fields.middleName === ''
      }
    }
  };
}

validations.lastName = {
  lastName: {
    ...validations.lastName.lastName,
    isRequired: VERIFY_DETAILS_MESSAGE_LAST_NAME
  }
};

if (FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
  const validateIf = ({ fields }) =>
    fields.confirmLastNameIsEmpty === '' || fields.confirmLastNameIsEmpty === false;

  validations.lastName = {
    lastName: {
      isRequired: {
        message: VERIFY_DETAILS_MESSAGE_LAST_NAME,
        validateIf
      },
      isRegexMatch: {
        ...defaultLettersAndSymbolsRegexMatch,
        validateIf
      }
    },
    confirmLastNameIsEmpty: {
      isRequired: {
        message: '',
        validateIf: ({ fields }) => fields.lastName === ''
      }
    }
  };
}

export default validations;
